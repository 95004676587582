import React from "react";
import { graphql } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import useTranslations from "../hooks/useTranslations";
import { LocaleContext } from "../components/layout";
import { ExternalLinkIcon } from "@heroicons/react/outline";

import Hero from "../components/hero";
import LocalizedLink from "../components/localizedLink";
import { AslPromo } from "../components/aslPromo";

const IndexPage = (props) => {
  const { locale } = React.useContext(LocaleContext);
  const { homePage, abbreviation, actions } = useTranslations();

  const boatCategories = [
    {
      title: homePage.boats.licenseFree,
      excerpt: homePage.boats.licenseFreeText,
      href: "/boote?license=no",
      image: "boat-category-license-free.jpg",
    },
    {
      title: homePage.boats.license,
      excerpt: homePage.boats.licenseText,
      href: "/boote?license=yes",
      image: "boat-category-license.jpg",
    },
    {
      title: homePage.boats.raft,
      excerpt: homePage.boats.raftText,
      href: "/floesse-und-party-boote",
      image: "activity-flossfahrt.jpg",
    },
  ];
  const activities = [
    {
      title: homePage.activities.topic2,
      excerpt: homePage.activities.topic2Text,
      href: "/floesse-und-party-boote",
      image: "activity-party-to-night.jpg",
    },
    {
      title: homePage.activities.topic3,
      excerpt: homePage.activities.topic3Text,
      href: "/boote?boatType=muskelkraft",
      image: "activity-sup.jpg",
    },
    {
      title: homePage.activities.topic1,
      excerpt: homePage.activities.topic1Text,
      image: "activity-skipper.jpg",
    },
  ];
  const promos = [
    {
      title: homePage.promos.promo1,
      subTitle: homePage.promos.promo1text,
    },
    {
      title: homePage.promos.promo2,
      subTitle: homePage.promos.promo2text,
      href: "/online-buchen",
    },
    {
      title: homePage.promos.promo3,
      subTitle: homePage.promos.promo3text,
      href: "/gutscheine",
    },
  ];

  const getHeroImg = props.data.images.nodes.find((element) => {
    if (element.base === `hero-1.jpg`) {
      return true;
    }
    return false;
  });
  const heroImg = getImage(getHeroImg);
  const heroHeightScreen = true; // true set height to 100vh

  return (
    <>
      <Hero
        hScreen={heroHeightScreen}
        // heroTextAlignmentCenter={true}
        title="Berlin – Köpenick"
        preTitle={`${homePage.alsBoatRental}®`}
        heroImg={heroImg}
      />

      {/* BOATS */}
      <div className="p-6 mx-auto mt-16 max-w-7xl">
        <div className="grid gap-6 sm:gap-8 md:gap-12 md:grid-cols-3">
          <div className="">
            <h2 className="mb-3 text-xl font-bold">{homePage.boats.title}</h2>
            <div className="text-md leading-relaxed text-gray-500 prose">
              {locale === "en" ? (
                <>
                  <p>
                    With us you can explore the waters of Berlin and experience
                    Berlin from a different perspective. All boats are located
                    directly at our site at Lindenstr. 27, 12555 Berlin
                    (opposite the Alte Försterei between Wuhlheide and Altstadt
                    Köpenick next to Mellowpark).
                  </p>
                  <p>
                    We are there for everyone, available for almost everything
                    and relaxed, just like Berlin is. Whether you want to spend
                    romantic hours on the water as a couple, go swimming with
                    the family, explore the city from the water as a team or
                    celebrate with friends and family on a raft, we have the
                    right boat or raft for you.
                  </p>
                  <p>
                    Many motorboats and party rafts are also licence-free.
                    Alternatively, you can let one of our experienced skippers
                    take you across the Spree, Dahme, canals and Müggelsee. All
                    boats can be booked directly online. 50% deposit when
                    booking, balance and deposit on site.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Mit uns kannst du die Gewässer Berlins unsicher machen und
                    Berlin aus einem anderen Blickwinkel erleben. Sämtliche
                    Boote liegen direkt an unserem Standort in der Lindenstr.
                    27, 12555 Berlin (gegenüber der Alten Försterei zwischen
                    Wuhlheide und Altstadt Köpenick neben dem Mellowpark).
                  </p>
                  <p>
                    Wir sind für jeden da, für fast alles zu haben und
                    entspannt, wie Berlin eben ist. Ob ihr als Pärchen
                    romantische Stunden auf dem Wasser verbringen, mit der
                    Familie baden, als Team die Stadt vom Wasser erkunden oder
                    mit Freunden und Familie auf einem Floß feiern wollt, wir
                    haben das passende Boot oder Floß für euch.
                  </p>
                  <p>
                    Viele Motorboote und Partyflöße sind auch führerscheinfrei
                    zu fahren. Alternativ könnt ihr euch auch von einem unserer
                    erfahrenen Skipper über Spree, Dahme, Kanäle und den
                    Müggelsee schippern lassen. Alle Boote sind direkt online
                    buchbar. 50% Anzahlung bei Buchung, Restzahlung und Kaution
                    dann vor Ort.
                  </p>
                </>
              )}
            </div>
            <button
              onClick={() => mapsSelector()}
              className="mt-3 text-sm text-gray-500 hover:underline"
            >
              {locale === "en" ? `Open maps` : `Karte öffnen`}
              <ExternalLinkIcon className="inline h-4 ml-1 align-text-top" />
            </button>
          </div>
          <div className="md:col-span-2">
            <div className="md:sticky md:top-6">
              <div className="relative">
                <ul className="grid grid-cols-2 gap-6 sm:grid-cols-3 sm:gap-8 lg:gap-12">
                  {boatCategories.map((activity) => {
                    const activityImage = props.data.images.nodes.find(
                      (element) => {
                        if (element.base === activity.image) {
                          return true;
                        }
                        return false;
                      }
                    );
                    const image = getImage(activityImage);
                    return (
                      <li key={activity.title}>
                        <LocalizedLink
                          className="block text-sm"
                          to={activity.href}
                        >
                          <div className="">
                            {image && (
                              <GatsbyImage
                                image={image}
                                alt={activity.title}
                                className=""
                              />
                            )}
                          </div>
                          <h3 className="mt-4 mb-2 font-bold">
                            <span className="grid btn btn--skewed">
                              {activity.title}
                            </span>
                          </h3>
                          <div className="text-gray-500">
                            {activity.excerpt}
                          </div>
                        </LocalizedLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* PROMOS */}
      {promos.length > 0 && (
        <div className="grid my-16 overflow-hidden md:grid-cols-3 promos">
          {promos.map((promo, index) => {
            if (index > 2) return null;
            return (
              <div
                key={promo.title}
                className="px-6 py-4 text-center border border-gray-200 border--skewed"
              >
                {promo.href ? (
                  <LocalizedLink to={promo.href} className="group">
                    <div className="text-sm text-gray-500">
                      {promo.subTitle}
                    </div>
                    <div className="font-bold group-hover:underline group-focus:underline">
                      {promo.title}
                    </div>
                  </LocalizedLink>
                ) : (
                  <>
                    <div className="text-sm text-gray-500">
                      {promo.subTitle}
                    </div>
                    <div className="font-bold">{promo.title}</div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}

      {/* Banner Promo */}

      {/* ACTIVITIES */}
      <div className="p-6 mx-auto mt-16 max-w-7xl">
        <div className="grid gap-6 sm:gap-8 md:gap-12 md:grid-cols-3">
          <div className="md:order-2">
            <h2 className="mb-3 text-xl font-bold">
              {homePage.activities.title}
            </h2>
            {/* <div className="text-sm leading-relaxed text-gray-500">{homePage.activities.text}</div> */}
            <div className="prose">
              <p>Es lohnt sich, denn der Skipper...</p>
              <ul>
                <li>kennt die Gewässer und Regeln auf dem Wasser</li>
                <li>kennt die besten Spots zum Ankern und Baden</li>
                <li>
                  kann eure Wunschtouren umsetzen und viele Fragen zur Gegend
                  beantworten
                </li>
                <li>bleibt nüchtern und ihr könnt alle Alkohol trinken.</li>
                <li>kann die Fahrtdauer besser einschätzen</li>
                <li>
                  ermöglicht euch Fahrten bis tief in die Nacht! Nachtfahrten
                  (nach 22 Uhr) sind aus Sicherheitsgründen nur mit Skipper
                  möglich
                </li>
              </ul>
            </div>
          </div>
          <div className="md:col-span-2">
            <div className="md:sticky md:top-6">
              <div className="relative">
                <ul className="grid grid-cols-2 gap-6 sm:grid-cols-3 sm:gap-8 lg:gap-12">
                  {activities.map((activity) => {
                    const activityImage = props.data.images.nodes.find(
                      (element) => {
                        if (element.base === activity.image) {
                          return true;
                        }
                        return false;
                      }
                    );
                    const image = getImage(activityImage);
                    return (
                      <li key={activity.title}>
                        <LocalizedLink
                          className="block text-sm"
                          to={activity.href}
                        >
                          <div className="">
                            {image && (
                              <GatsbyImage
                                image={image}
                                alt={activity.title}
                                className=""
                              />
                            )}
                          </div>
                          <h3 className="mt-4 mb-2 font-bold">
                            <span className="grid btn btn--skewed">
                              {activity.title}
                            </span>
                          </h3>
                          <div className="text-gray-500">
                            {activity.excerpt}
                          </div>
                        </LocalizedLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ASL PROMOS */}
      <AslPromo />

      {/* TOURS */}
      <div className="relative grid mt-10 md:mt-32">
        <div className="z-10 grid col-start-1 row-start-1 gap-6 px-4 pb-48 mx-auto lg:grid-cols-3 max-w-7xl sm:px-6 lg:pb-64">
          <div className="mb-10 max-w-prose">
            <h2 className="mb-3 text-xl font-bold">{homePage.tours.title}</h2>
            <div className="mb-4 text-sm leading-relaxed text-gray-500">
              {homePage.tours.text}
            </div>
            <LocalizedLink to="/touren" className="btn btn--skewed">
              {actions.viewAll}
            </LocalizedLink>
          </div>

          <div className="grid grid-flow-row gap-6 sm:grid-cols-2 lg:col-span-2">
            {props.data.allGoogleTourenSheet.nodes.slice(0, 4).map((node) => {
              const title = locale === "de" ? node.titleDe : node.titleEn;
              const fahrzeitEinheit =
                locale === "de"
                  ? node.fahrzeitEinheitDe
                  : node.fahrzeitEinheitEn;
              const featuredImage = props.data.tourImages.nodes.find(
                (element) => {
                  if (element.base === `${node.featuredImage}.jpg`) {
                    return true;
                  }
                  return false;
                }
              );
              const image = getImage(featuredImage);
              return (
                <div
                  className="grid grid-cols-1 bg-white shadow-md"
                  key={node.slugDe}
                >
                  <div className="inline-block overflow-hidden">
                    <LocalizedLink to={`/touren/${node.slugDe}`}>
                      {image && (
                        <GatsbyImage
                          image={image}
                          alt={node.titleDe}
                          className="duration-700 scale-105 hover:scale-100"
                        />
                      )}
                    </LocalizedLink>
                  </div>
                  <div className="flex items-baseline gap-3 p-4">
                    <h3 className="text-lg font-semibold text-slate-700">
                      <LocalizedLink to={`/touren/${node.slugDe}`}>
                        {title}
                      </LocalizedLink>
                    </h3>
                    <div className="ml-auto min-w-max text-slate-500">{`${abbreviation.approx} ${node.fahrzeit} ${fahrzeitEinheit}`}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <StaticImage
          className="col-start-1 row-start-1 max-h-[1000px]"
          src="../assets/images/treptow.jpeg"
        />
      </div>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query index {
    images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(width: 1920)
        }
      }
    }
    allGoogleTourenSheet(limit: 4) {
      nodes {
        titleDe
        titleEn
        featuredImage
        slugDe
        fahrzeit
        fahrzeitEinheitDe
        fahrzeitEinheitEn
      }
    }
    tourImages: allFile(filter: { sourceInstanceName: { eq: "tourImages" } }) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(width: 640, aspectRatio: 1.49)
        }
      }
    }
  }
`;

function mapsSelector() {
  if (
    /* if we're on iOS, open in Apple Maps */
    /iPad|iPhone|iPod/.test(navigator.userAgent)
  )
    window.open(
      "maps://maps.google.com/maps?daddr=52.45387770981375,13.56780472576856&amp;ll="
    );
  /* else use Google */ else
    window.open(
      "https://maps.google.com/maps?daddr=52.45387770981375,13.56780472576856&amp;ll="
    );
}
