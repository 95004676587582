import React from "react";
import { LocaleContext } from "./layout";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import SvgLogoASL from "./svgLogoASL";

export const AslPromo = () => {
  const { locale } = React.useContext(LocaleContext);

  return (
    <div className="grid items-center my-6 relative">
      <StaticImage
        src="../assets/images/asl-call-out.jpg"
        alt="Your Image"
        className="max-h-96 sm:max-h-72 object-cover min-h-full col-start-1 row-start-1"
      />
      <div className="relative col-start-1 row-start-1 text-white text-center py-10">
        <h4 className="mb-2 text-lg mx-auto">
          {locale === "de" ? "Kein Boot verfügbar?" : "No boat available?"}
        </h4>
        <h4 className="mx-auto mb-6 font-serif text-2xl font-bold leading-tight sm:text-3xl ">
          {locale === "de"
            ? "Schaut bei ASL-Boote vorbei"
            : "Check out ASL-Boats"}
        </h4>
        <a
          href="https://asl-boote.de/"
          rel="noopener"
          className="inline-block mb-3 bg-[#1D2089] py-3 px-6 font-bold"
        >
          {locale === "de" ? "Zu ASL-Boote wechseln" : "Go to ASL"}
        </a>
        {/* show a small flag like container with the ASL logo and a link to the ASL website */}
        <a
          href="https://asl-boote.de/"
          rel="noopener"
          className="absolute -bottom-6 right-0 mb-6 mr-6 bg-white p-4 translate-y-1/2 shadow-lg"
        >
          <SvgLogoASL
            alt="ASL Logo"
            className="w-20 md:w-32 fill-current text-[#1D2089]"
           />
        </a>
      </div>
    </div>
  );
};
